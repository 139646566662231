import { UserDetails } from '@myneva-portals/projects/src/components';
import { IUser, RestUsersService } from '@myneva-portals/services/src/services';
import {
  LoadingWrapper,
  showToast,
  ErrorTimeout,
} from 'front-components/src/components';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { updateAdminDetails } from '../../state/reducers/admins-details';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { EFormValidation } from 'front-components/src/constants';
import {
  defaultErrorToast,
  formErrorToast,
  redirectErrorToast,
} from '../../components/sections/static-data/default-error-toast';

export interface AdminsDetailsContentProps {
  adminId: string;
  isEdit: boolean;
}

export const AdminsDetailsContent: React.FC<AdminsDetailsContentProps> = (
  props: AdminsDetailsContentProps
) => {
  const dispatch = useDispatch();
  const { breadcrumbs } = useSelector(navigationSelector);
  const url = process.env.WEB_GATEWAY_URL + '/core/';
  const userService = new RestUsersService(url);
  const { t } = useTranslation();
  const toastConfig = defaultErrorToast();
  const redirectToastConfig = redirectErrorToast();
  const formToastConfig = formErrorToast();

  let localErrors = [];

  const [customBreadcrumb, setCustomBreadcrumb] = useState(
    breadcrumbsUtils.getBreadcrumbs(breadcrumbs)
  );
  const [isSessionError, setIsSessionError] = React.useState(false);

  const { isLoading, error, data, refetch } = useQuery(
    'getAdminDetailsQuery',
    () => userService.getUser(props.adminId).then((data: IUser) => data),
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const { mutate: deleteAdmin } = useMutation(
    (userID: string) =>
      userService.deleteUser(userID).then(() => navigate('/users')),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const handleSave = async (user: IUser, setError) => {
    try {
      await userService.updateUser(user);
      refetch();
      navigate(`/users/${props.adminId}`, { state: { isEdit: false } });
    } catch (e) {
      if (e.response.status == 400) {
        e?.response.data.errors.map((item) => {
          const fieldName =
            item.fieldName && item.fieldName.replaceAll('_', '');
          localErrors.push(EFormValidation.DUPLICATE + fieldName);
          setError(fieldName, EFormValidation.DUPLICATE + fieldName);
        });
        showToast(formToastConfig, localErrors);
        localErrors = [];
      }
    }
  };

  const handleEdit = () => {
    navigate(`/users/${props.adminId}`, { state: { isEdit: true } });
  };

  const handleExit = () => {
    navigate(`/users/${props.adminId}`, { state: { isEdit: false } });
  };

  const handleDelete = async (userID: string) => {
    deleteAdmin(userID);
  };

  const getUserName = () => data?.firstName + ' ' + data?.lastName;

  const getBreadcrumbName = () => {
    return props.isEdit ? `${t('EDIT')} (${getUserName()})` : getUserName();
  };

  useEffect(() => {
    if (data) {
      const customBreadCrumbConfig = [
        {
          breadcrumbName: getBreadcrumbName(),
          path: '/users/' + data.id,
        },
      ];
      setCustomBreadcrumb(
        breadcrumbsUtils.getBreadcrumbs(breadcrumbs, customBreadCrumbConfig)
      );
      dispatch(updateAdminDetails(data));
    }
  }, [data, props.isEdit]);

  const content = (
    <UserDetails
      user={data}
      isEdit={props.isEdit}
      header={t('EDIT_USER_HEADER')}
      toastConfig={formToastConfig}
      formikError={localErrors}
      onEdit={handleEdit}
      onSave={handleSave}
      onExit={handleExit}
      onDelete={handleDelete}
      breadcrumbs={customBreadcrumb}
      translation={t}
    />
  );

  const errorComponent = (
    <ErrorTimeout
      header={t('TIMEOUT_ERROR')}
      text={t('TIMEOUT_ERROR_MSG')}
      buttonLabel={t('REFETCH_BUTTON_LABEL')}
      onButtonClick={() => refetch()}
    />
  );

  return (
    <LoadingWrapper
      content={error ? errorComponent : content}
      isLoading={isLoading || isSessionError}
      useLineProgress={true}
    />
  );
};
