import { PortalLayout } from 'front-components/src/components';
import { PortalHeader } from '../../components/portal-header/portal-header';
import { AdminsDetailsContent } from '../../content/admins/admins-details';
import { AdminMenu } from '../../components/asside-menu/asside-menu';
import React from 'react';

function UsersDetailsPage({ location, params }) {
  const asside = <AdminMenu />;
  return (
    <main>
      <title>Admin</title>
      <PortalLayout header={<PortalHeader />} asside={asside}>
        <AdminsDetailsContent
          adminId={params.name}
          isEdit={location.state.isEdit}
        />
      </PortalLayout>
    </main>
  );
}

export default UsersDetailsPage;
